<template>
    <div class="wrapper container">
        <h1>AILaw INC. PRIVACY POLICY</h1>
        <div class="item">
            <p>AILaw INC. (the “Company”) committed to maintaining robust privacy protections for its users. Our Privacy Policy (“Privacy Policy”) is designed to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our Service.
            </p>
        </div>
        <div class="item">
            <p>For purposes of this Agreement, “Service” refers to the Company’s service which can be accessed via our website or through our mobile application. In which users can obtain a general understanding of the law. The terms “we,” “us,” and “our” refer to the Company. “You” refers to you, as a user of Service. By accepting our Privacy Policy and Terms of Use, you consent to our collection, storage, use and disclosure of your information as described in this Privacy Policy.
            </p>
        </div>
        <div class="item">
            <h3>I. INFORMATION WE COLLECT</h3>
            <p>We collect “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences that are generated based on the data you submit and number of clicks.
            </p>
            <p>Personal Information includes, but is not limited to, name, gender, age, marital status, immigration status, immigration related documents, country of origin, nationality, information related to government-issued papers, educational and working experience, past and present address, telephone and fax number, IRS documents, email address etc.
            </p>
        </div>
        <div class="item">
            <h3>1. Information collected via Technology
            </h3>
            <p>To activate the Service you do not need to submit any Personal Information other than your email address. To use the Service thereafter, you do not need to submit further Personal Information. However, in an effort to improve the quality of the Service, we track information provided to us by your browser or by our mobile application when you view or use the Service, such as the website you came from (known as the “referring URL”), the type of browser you use, the device from which you connected to the Service, the time and date of access, and other information that does not personally identify you. We track this information using cookies, or small text files which include an anonymous unique identifier. Cookies are sent to a user’s browser from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us to collect Non-Personal information about that user and keep a record of the user’s preferences when utilizing our services, both on an individual and aggregate basis.
            </p>
            <p>The Company may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and until you delete them, while session cookies expire when you close your browser.
            </p>
        </div>
        <div class="item">
            <h3>2. Information you provide us by registering for an account</h3>
            <p>In addition to the information provided automatically by your browser when you visit the Site, to become a subscriber to the Service you will need to create a personal profile. You can create a profile by registering with the Service and entering your email address, and creating a user name and a password. By registering, you are authorizing us to collect, store and use your email address in accordance with this Privacy Policy.
            </p>
        </div>
        <div class="item">
            <h3>II. HOW WE USE AND SHARE INFORMATION</h3>
        </div>
        <div class="item">
            <h3>1. Personal Information:</h3>
            <p>Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent. We do share Personal Information with vendors who are performing services for the Company, such as the servers for our email communications who are provided access to user’s email address for purposes of sending emails from us. Those vendors use your Personal Information only at our direction and in accordance with our Privacy Policy. In general, the Personal Information you provide to us is used to help us communicate with you. For example, we use Personal Information to contact users in response to questions, solicit feedback from users, provide technical support, and inform users about promotional offers
            </p>
        </div>
        <div class="item">
            <h3>2. Non-Personal Information</h3>
            <p>In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We also aggregate Non-Personal Information in order to track trends and analyze use patterns on the Site. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal Information to our partners, advertisers and other third parties at our discretion.
            </p>
            <p>In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our assets may continue to process your Personal Information as set forth in this Privacy Policy. If our information practices change at any time in the future, we will post the policy changes to the Site so that you may opt out of the new information practices. We suggest that you check the Site periodically if you are concerned about how your information is used.
            </p>
        </div>
        <div class="item">
            <h3>3. Sharing with third parties</h3>
            <p>A. Sharing with registered agent: If you authorize the Company to designate a third party as the registered agent for your business entity, the Company may provide your Non-personal and Personal Information to that party.
            </p>
            <p>B. Sharing with service providers: We may share your Non-personal and Personal Information with companies that provide support services to us (such as a printer or email service provider), help us market our products and services, or for training purposes. These companies may need information about you in order to perform their functions. These companies are not authorized to use the information we share with them for any other purpose.
            </p>
            <p>C. Sharing with attorneys: With your informed and express consent, we may, only to the extent that it is necessary, share your Non-personal and Personal Information, with an attorney who will provide consultation and in some cases, legal services to you. Please be advised that such attorney is not an agent or employee of the Company. Although you may have other rights and privileges with respect to your communication with such attorney (for instance, the attorney-client privilege), the Company is not responsible for any breach or violation thereof.</p><p>D. Sharing due to merger, acquisition and other corporate reorganization: If the Company is merged, acquired, or sold, or if some or all of our assets or equity are transferred, the Company may disclose or transfer Personal and Non-personal Information in connection with such transaction.
            </p>
        </div>
        <div class="item">
            <h3>III. HOW WE PROTECT INFORMATION</h3>
            <p>We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. We further protect your information from potential security breaches by implementing certain technological security measures including encryption, firewalls and secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand and agree to assume these risks.
            </p>
        </div>
        <div class="item">
            <h3>IV. YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION
            </h3>
            <p>You have the right at any time to prevent us from contacting you for marketing purposes. When we send a promotional communication to a user, the user can opt out of further promotional communications by following the unsubscribe instructions provided in each promotional e-mail. You can also indicate that you do not wish to receive marketing communications from us in the “Settings” section of the Site. Please note that notwithstanding the promotional preferences you indicate by either unsubscribing or opting out in the Settings section of the Site, we may continue to send you administrative emails including, for example, periodic updates to our Privacy Policy.
            </p>
        </div>
        <div class="item">
            <h3>V. LINKS TO OTHER WEBSITES</h3>
            <p>As part of the Service, we may provide links to or compatibility with other websites or applications. However, we are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Site and the Service. Therefore, this Privacy Policy does not apply to your use of a third party website accessed by selecting a link on our Site or via our Service. To the extent that you access or use the Service through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.
            </p>
        </div>
        <div class="item">
            <h3>VI. CHANGES TO OUR PRIVACY POLICY</h3>
            <p>The Company reserves the right to change this policy and our Terms of Service at any time. We will notify you of significant changes to our Privacy Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site. Significant changes will go into effect 30 days following such notification. Non-material changes or clarifications will take effect immediately. You should periodically check the Site and this privacy page for updates.
            </p>
        </div>
        <div class="item">
            <h3>VII. CONTACT US</h3>
            <p>If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us.
            </p>
        </div>
    </div>
</template>

<script>
  export default {
    name: "Policy"
  }
</script>

<style scoped>

</style>
